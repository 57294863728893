import React from "react";

function Announcement(props){
    return(
        <div className="announcement shadow">
            <div dangerouslySetInnerHTML={{ __html: props.message }} />
            <a href={props.btn_url} className={ `btn btn-info ${props.btn_url && props.btn_label ? '' : 'd-none' } ` } dangerouslySetInnerHTML={{ __html: props.btn_label }} />
        </div>
    );
}

export default Announcement;