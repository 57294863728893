import React, { Component } from 'react';

export default class RedirectToHome extends Component {




  redirect() {
    setTimeout(() => {
      this.props.history.push('/')
    }, 3000)

  }




  render() {
    return (
      <div className="page">
        <div className="centerize white-bg">
          <h1 className="noor-h1">
            Dang it, you're lost....
          </h1>
          Hold tight, we're taking you back to <a href="/">home</a>.
        </div>
        {this.redirect()}
      </div>
    );
  }
}
