import React,{useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import http from "../assets/http";

function LectureAdmin() {
  const [speaker, setSpeaker] = useState("");
  const [category, setCategory] = useState("");
  const [topic, setTopic] = useState("");
  const [url, setUrl] = useState("");
  const [lecture_date, setLecture_date] = useState("");

  const clearState = () => {
    setSpeaker("");
    setCategory("");
    setTopic("");
    setUrl("");
    setLecture_date("");
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    e.persist();
    e.target.classList.add('loading');

    const lecture = {speaker, category, topic, url, lecture_date};

    let data = new FormData();
    data.set('lecture', JSON.stringify(lecture));
    data.set('method', 'add_lecture');

    http.post('admin.php', data)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok) {
          e.target.classList.remove('loading');
          clearState();
        }
      });
  }




  return (
    <div id="audio" className="page">
      <div className="white-bg centerize" style={{ minWidth: 500 }}>

        <h3 className="noor-h1">Lecture</h3>

        <div className="shadow p-3 my-5" >
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              type="text"
              required
              value={speaker}
              className="form-control my-2"
              placeholder="Speaker Name..."
              title="Speaker Name..."
              onChange={(e)=>setSpeaker(e.target.value)} />

            <input
              type="text"
              required
              value={category}
              className="form-control my-2"
              placeholder="Category..."
              title="Category..."
              onChange={(e)=>setCategory(e.target.value)} />

            <input
              type="text"
              required
              value={topic}
              className="form-control my-2"
              placeholder="Topic..."
              title="Topic..."
              onChange={(e)=>setTopic(e.target.value)} />

            <input
              type="text"
              required
              value={url}
              className="form-control my-2"
              placeholder="Url..."
              title="Url..."
              onChange={(e)=>setUrl(e.target.value)} />

            <input
              type="date"
              required
              value={lecture_date}
              className="form-control my-2"
              placeholder="Lecture date..."
              title="Lecture date..."
              onChange={(e)=>setLecture_date(e.target.value)} />

            <button 
              type="submit" 
              className="btn btn-success roundBtn" 
              title="Save"><FontAwesomeIcon icon={faCheck} />
            </button>
          </form>
        </div>


      </div>
    </div>
  );
}
export default LectureAdmin
