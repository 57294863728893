

export default class helper {




  /**
   * Converts array of key value pair to a single object
   */
  static ArraytoObj(p) {

    var rObj = {};
    let data = p.map(c => {
      rObj[c.id] = c.data;
      return rObj;
    })[0];

    return data;
  }




}