import React from 'react';
import './contact.css';

import { faMapMarkerAlt, faMapMarked, faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Contact(props) {

	if(!props.contact) return '';

	return (
		<section id="Contact" className="page">
			<div className="centerize white-bg">
				<div className="icon">
					<i><FontAwesomeIcon icon={faMapMarkerAlt} transform="up-4" /></i>
				</div>
				<h2 className="noor-h1">Contact</h2>
				{
					props.contact.phone ?
						<div>
							<FontAwesomeIcon className="d-inline-block mr-2 text-muted" icon={faPhone} />
							<a href={`tel:${props.contact.phone}`} dangerouslySetInnerHTML={{ __html: props.contact.phone }} />
						</div>
					: ''
				}
				{
					props.contact.email ?
						<div>
							<FontAwesomeIcon className="d-inline-block mr-2 text-muted" icon={faAt} />
							<a href={`mailto:${props.contact.email}`} dangerouslySetInnerHTML={{ __html: props.contact.email }} />
						</div>
					: ''
				}
				{
					props.contact.address ?
					<div>
						<FontAwesomeIcon className="d-inline-block mr-2 text-muted" icon={faMapMarked} />
						<span className="d-inline-block" dangerouslySetInnerHTML={{ __html: props.contact.address }} />
					</div>
					: ''
				}
				<iframe src={props.contact.maps_iframe} allowFullScreen frameBorder="0" title="map"></iframe>
			</div>
		</section>
	);
}

export default Contact;