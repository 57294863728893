import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faAt, faCheck, faMapMarked, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import helper from "../assets/helper";
import http from "../assets/http";

export default class ContactAdmin extends React.Component {




  constructor() {
    super()

    this.state = {
      contact: {
        email: '',
        address: '',
        maps_iframe: '',
        phone: '',
        fb: '',
        insta: '',
        twitter: '',
      },
    }




    /**
     * Modules for QuillJs WYSIWYG editor
     */
    this.quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image'],
        ['clean'],
      ],
    }
  }




  /**
   * Get contact
   */
  componentDidMount() {
    this.setState({
      contact: this.props.contact,
    })
  }




  /**
   * Binding for QuillJs
   */
  handleQuillChange = (value) => {
    // console.log(`TCL: ContactAdmin -> handleQuillChange -> this.props.contact`, this.props.contact)
    // let contact = [...this.props.contact]
    // contact.address = value
    // this.setState({ contact: {
    //   address: value
    // } })
  }




  /**
   *  Binding for all inputs (except QuillJs)
   * */
  handleChange = (e) => {

    let data = {};

    for (const key in this.refs) {
      if (this.refs.hasOwnProperty(key)) {
        data[key] = this.refs[key].value;
      }
    }

    data['address'] = this.refs.address.getEditorContents();



    this.setState({
      contact: data
      // {
      //   email: this.refs.email.value,
      //   maps_iframe: this.refs.maps_iframe.value,
      //   phone: this.refs.phone.value,
      //   fb: this.refs.fb.value,
      //   twitter: this.refs.twitter.value,
      //   insta: this.refs.insta.value,
      //   address: this.refs.address.getEditorContents(),
      // }
    })

  }




  // Save an item
  saveItem(e) {
    e.preventDefault()
    e.persist()

    // Verify empty HTML
    if (!this.state.contact.address ||
      this.state.contact.address === "<p><br></p>" ||
      this.state.contact.address.replace(/(<p>|<\/p>|<br>|&nbsp;|\s+)*/g, '') === "") {
      alert("Cannot save an empty details!!!")
      return;
    }

    // Add loading effect
    e.target.classList.add('loading');

    // Create form data
    let form = new FormData()
    form.set('method', 'update_info')
    form.set('property', 'contact')
    form.set('data', JSON.stringify(this.state.contact))

    // Send form data
    http.post('admin.php', form)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok && res.hasOwnProperty('data') && res.data[0]) {



          this.setState({
            contact: helper.ArraytoObj(res.data),
          })
          e.target.classList.remove('loading');
        }
        else {
          alert('error');
          e.target.classList.remove('loading');
        }
      }, () => {
        alert('error');
        e.target.classList.remove('loading');
      })
  }




  render() {
    return (
      <div id="Contact" className="page">
        <div className="white-bg centerize" style={{ minWidth: 500 }}>

          <h3 className="noor-h1">Contact</h3>
          <form id="ContactForm" onSubmit={(value) => this.saveItem(value)} >

            <div className="position-relative">
              <FontAwesomeIcon icon={faPhone} className="inputIcon" />
              <input
                placeholder="Phone number..." title="Phone number..."
                className="form-control my-2" ref="phone"
                type="tel" minLength="10" maxLength="12"
                value={this.state.contact.phone} onChange={this.handleChange} />
            </div>




            <div className="position-relative">
              <FontAwesomeIcon icon={faAt} className="inputIcon" />
              <input
                placeholder="Email address..." title="Email address..."
                className="form-control my-2" type="email" ref="email"
                value={this.state.contact.email} onChange={this.handleChange} />
            </div>


            <ReactQuill modules={this.quillModules}
              placeholder="Contact us message..." title="Contact us message..."
              ref="address" className="my-2"
              value={this.state.contact.address} onChange={this.handleQuillChange} />

            <div className="position-relative">
              <FontAwesomeIcon icon={faMapMarked} className="inputIcon" />
              <input
                placeholder="Maps iframe url..." title="Maps iframe url..."
                className="form-control my-2" type="url" ref="maps_iframe"
                value={this.state.contact.maps_iframe} onChange={this.handleChange} />
            </div>

            <hr />

            <p className="lead">Footer Social Icons</p>

            <div className="position-relative">
              <FontAwesomeIcon icon={faTwitter} className="inputIcon" />
              <input
                placeholder="Twitter link..." title="Twitter link..."
                className="form-control my-2" type="url" ref="twitter"
                value={this.state.contact.twitter} onChange={this.handleChange} />
            </div>

            <div className="position-relative">
              <FontAwesomeIcon icon={faFacebookF} className="inputIcon" />
              <input
                placeholder="Facebook link..." title="Facebook link..."
                className="form-control my-2" type="url" ref="fb"
                value={this.state.contact.fb} onChange={this.handleChange} />
            </div>

            <div className="position-relative">
              <FontAwesomeIcon icon={faInstagram} className="inputIcon" />
              <input
                placeholder="Instagram link..." title="Instagram link..."
                className="form-control my-2" type="url" ref="insta"
                value={this.state.contact.insta} onChange={this.handleChange} />
            </div>

            <button type="submit" className="btn btn-success roundBtn" title="Save"><FontAwesomeIcon icon={faCheck} /></button>
          </form>
        </div>
      </div>
    )
  }




}