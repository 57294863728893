import http from "../assets/http";

export default new class Auth {




  constructor() {
    this.authenticated = false;
    this.alreadyValidating = false;
  }




  login(cb) {
    this.authenticated = true;
    cb();

  }




  logout(cb) {
    this.authenticated = false;
    this.clearToken();
    cb();
  }




  isAuthenticated() {
    return this.authenticated;
  }




  validateToken(cb) {
    if ('token' in localStorage) {
      if (this.alreadyValidating) {
        return;
      }

      this.alreadyValidating = true;

      let data = new FormData()
      data.set('method', 'validate_token')
      data.set('token', localStorage.token)

      http.post('admin.php', data)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok && res.data.hasOwnProperty('token')) {
          localStorage.setItem('token', res.data.token)
          this.alreadyValidating = false;
          cb(true);
          return true;
        }
        else {
          this.alreadyValidating = false;
          this.logout(() => {
            this.clearToken();
            cb(false);
          });
          return false;
        }
      });

    }
  }




  clearToken() {
    localStorage.clear();
  }




  hasToken() {
    let ls = localStorage;
    return ls.hasOwnProperty('token') && ls.token !== null && ls.loken !== "";
  }



}
