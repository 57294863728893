import React, { useEffect, useState } from "react";
import Lecture from "./Lecture";
import http from "../assets/http";
import helper from "../assets/helper";

import { faPlayCircle, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'moment';

function Lectures(props) {
  const [theUrl, setTheUrl] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [recordingList, setRecordingList] = useState();

  const setLectures = (rec)=>{
    setRecordingList(rec.map(r =>
      <Lecture
        key={r.id}
        speaker={r.Speaker}
        category={r.Category}
        topic={r.Topic}
        url={r.URL}
        lecture_date={Moment(r.Recording_Date).format('MM/DD/yyyy')}
        changeTrack={changeTrack}
      />
    ))
  }


  const changeTrack = (url) => {
    var aud = document.getElementById('playa');
    setTheUrl(url);
    aud.autoplay = true
    aud.load();
  }
  const nav = (dir) => {
    if (dir == "nx") {
      setPage(page+1);
    } else {
      setPage(page-1);
    }
  }

  useEffect(() => {
    http.get('announcements.php?page=' + page )
      .then(Response => Response.json())
      .then(res => {
        setTotalPages(res.pages.totalPages);
        setLectures(res.recordings);
      })
      .catch(error => {
        console.log(error);
      })
  }, [page]);

  var preCl = page == 1 ? "hideme" : "prev";
  var nextCl = page == totalPages ? "hideme" : "next";

  return (
    <section id='audio' className='page'>
      <dev className="centerize white-bg">
        <div className="icon">
          <i><FontAwesomeIcon icon={faPlayCircle} transform="up-4" /></i>
        </div>
        <h2 className="noor-h1">Lectures</h2>
        <audio id='playa' controls src={theUrl}></audio>
        <table className="table table-striped">
          <thead>
            <tr>
              <th> </th>
              <th>Category</th>
              <th>Topic</th>
              <th>Speaker</th>
              <th className="d-none d-md-block">Date</th>
            </tr>
          </thead>
          <tbody>
            {recordingList}
          </tbody>
        </table>
        <dev>
          <a className={preCl} onClick={() => nav('pr')}><FontAwesomeIcon icon={faAngleLeft} /></a>
          <span>{page}/{totalPages}</span>
          <a className={nextCl} onClick={() => nav('nx')}><FontAwesomeIcon icon={faAngleRight} /></a></dev>
      </dev>
    </section>
  );

}
export default Lectures