import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import http from "../assets/http";
import img1 from '../images/masjidnoor.png';
import AnnounceAdmin from "./admin.announce";
import CurrentPrayerAdmin from "./admin.currentprayer";
import LectureAdmin from "./admin.lecture"
import ServicesAdmin from "./admin.services";
import ContactAdmin from "./admin.contact";
import AboutAdmin from "./admin.about";
import "./adminstyles.css";
import auth from "../login/auth";
import helper from "../assets/helper";

class AdminPage extends React.Component {

  constructor() {
    super()
    this.state = {
      loading: true,
      prayerTimes: {},
      isLoggedOut: auth.isAuthenticated(),
      contact: {email: '', address: '', map: ''},
    };


    http.get('announcements.php')
      .then(res => res.json())
      .then(res => {


        let info = helper.ArraytoObj(res.info)

        this.setState({
          loading: false,
          prayerTimes: res.currentTime,
          announcements: res.announcements,
          services: info.services,
          about: info.about,
          contact: info,
        })
      });

    auth.isAuthenticated();
  }

  // Masjid Logo
  // Masjid Back Pic Desktop
  // Masjid Back Pic Mobile

  // Payer TimeTable
  // year round
  // current  ---  done

  //Announcement  ---  done
  // an
  // btn
  // btn color --- X
  // link

  // Madrassa
  // 3x fields

  // Service  ---  done
  // List
  // imgs? --- X

  // About  ---  done
  // data

  // Contact  ---  done
  // email
  // Address
  // Map iframe link

  // Footer
  // text
  // social icons  ---  done

  render() {

    if (this.state.loading === true) {
      return (
        <div className="page">
          <div className="centerize white-bg">
            <h1 className="noor-h1">
              Loading
              <FontAwesomeIcon icon={faSpinner} className={this.state.loading ? 'ml-3 text-danger' : 'd-none'} spin />
            </h1>
          </div>
        </div>
      );
    }


    // THIS IS NOT WORKING!!!!!!!!!!!!
    if (auth.isAuthenticated() !== true) {
      return (
        <div className="page">
          <div className="centerize white-bg">
            <h1 className="noor-h1">
              Logged Out
              </h1>
            <a className="btn btn-dark" href="/">Login again</a>
          </div>
        </div>
      );
    }

    return (

      <div>
        <div id="logo" className="logo d-flex logo-state2">
          <a className="logo-wrapper centerize" href="/">
            <img className="logo-image" src={img1} alt="" />
            <div className="logo-text">
              <h1 className="noor-h1 logo-text-en">Masjid Al-Noor</h1>
              <h1 className="noor-h1 logo-text-ar">مسجد النور</h1>
            </div>
          </a>
        </div>
        <div className="page">
          <div className="centerize white-bg">
            <h1 className="noor-h1">Hello Admin</h1>
            <div className="d-flex justify-content-around flex-column flex-wrap">
              <a className="m-2" href="#Announcements">Announcements</a>
              <a className="m-2" href="#Currentnamaz">Current Prayer Times</a>
              <a className="m-2" href="#Services">Services</a>
              <a className="m-2" href="#About">About</a>
              <a className="m-2" href="#Contact">Contact</a>
              <Link className="m-2" to="/admin/logout">Logout</Link>
            </div>
          </div>
        </div>

        <AnnounceAdmin announcements={this.state.announcements} />
        <CurrentPrayerAdmin times={this.state.prayerTimes} />
        <LectureAdmin />
        <ServicesAdmin services={this.state.services} />
        <AboutAdmin about={this.state.about} />
        <ContactAdmin contact={this.state.contact} />
      </div>
    )
  }

}
export default AdminPage;
