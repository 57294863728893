import React from 'react';
import { faMosque } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Madrassa(){
    return(
		<section id="Madrassa" className="page">
			<div className="centerize white-bg">
				<div className="icon">
					<i><FontAwesomeIcon icon={faMosque} transform="up-4"/></i>
				</div>
				<h2 className="noor-h1">Madrassa</h2>

				<table align="center" cellSpacing="0" cellPadding="5">
					<tbody>
						<tr>
							<th>Teacher</th>
							<td className="p-2 text-left">Muhammad Kashif Mansoor</td>
						</tr>
						<tr>
							<th>Services</th>
							<td className="p-2 text-left">Reading and Learning by heart</td>
						</tr>
						<tr>
							<th>Schedule</th>
							<td className="p-2 text-left">Saturday and Sunday From 10 am to 12 am<br/>
							Monday and Tueseday From 2 pm to 4 pm</td>
						</tr>
					</tbody>
				</table>

			</div>
		</section>

    );
}

export default Madrassa;