import React from "react";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import http from "../assets/http";

export default class AnnounceAdmin extends React.Component {




  constructor() {
    super()

    // Generate first blank announcement
    this.state = {
      announces: [
        { announcement: '', id: 0, position: '', btn_label: '', btn_url: '' },
      ],
    }




    // Modules for QuillJs WYSIWYG editor
    this.quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean']
      ],
    }
  }




  componentDidMount() {
    // Get announcements
    this.setState({
      announces: this.props.announcements
    })
  }




  handleQuillChange = (value, id) => {
    // Binding for QuillJs
    let announces = [...this.state.announces]
    announces[id]['announcement'] = value
    this.setState({ announces: announces })
  }




  handleChange = (e) => {
    // Binding for all inputs (except QuillJs)
    if (e.target.hasAttribute('data-id')) {
      let announces = [...this.state.announces]
      announces[e.target.dataset.id][e.target.dataset.name] = e.target.value
      this.setState({ announces: announces })
    }
  }




  // Add new item
  newItem = (e) => {

    // Check if new unsaved item already exists
    let newArray = this.state.announces.filter(function (el) {
      return el.id === 0;
    })
    if (newArray.length) {
      alert("Please save recently added annoucement before adding another.");
      return;
    }
    // Add new item
    this.setState((prevState) => ({
      announces: [{ announcement: '', id: 0, position: '', btn_label: '', btn_url: '' }, ...prevState.announces],
    }));
  }




  // Save an item
  saveItem(e, id) {
    e.preventDefault()
    e.persist()

    // Verify empty HTML
    if (!this.state.announces[id].announcement ||
      this.state.announces[id].announcement === "<p><br></p>" ||
      this.state.announces[id].announcement.replace(/(<p>|<\/p>|<br>|&nbsp;|\s+)*/g, '') === "") {
      alert("Cannot save an mpty announcement!!!")
      return;
    }

    // Add loading effect
    e.target.classList.add('loading');

    // Create form data
    let form = new FormData()
    form.set('announces', JSON.stringify(this.state.announces[id]))
    form.set('method', 'update_announcement')

    // Send form data
    http.post('admin.php', form)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok && res.hasOwnProperty('data') && res.data[0]) {

          this.setState({ announces: res.data })
          e.target.classList.remove('loading');

        }
      })
  }




  // Delete an item
  deleteItem(e, id) {
    e.preventDefault()
    e.persist()


    let ask = window.confirm("This action cannot be undone.\nAre you sure you want to delete?");
    if (!ask) return;


    // If an unsaved item exists, then no need for DB call
    if (id === 0) {
      let newArray = this.state.announces.filter(function (el) {
        return el.id !== 0;
      })
      this.setState({ announces: newArray });
      return;
    }


    // Add loading effect
    e.target.parentNode.classList.add('loading');
    e.target.parentNode.parentNode.classList.add('loading');

    // Generate form data
    let form = new FormData()
    form.set('id', id)
    form.set('method', 'delete_announcement')

    // Send form data
    http.post('admin.php', form)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok && res.hasOwnProperty('data')) {
          e.target.parentNode.classList.remove('loading');
          e.target.parentNode.parentNode.classList.remove('loading');
          this.setState({
            announces: res.data
          })
        }
      })
  }




  render() {
    return (
      <div id="Announcements" className="page">
        <div className="white-bg centerize" style={{ minWidth: 500 }}>

          <h3 className="noor-h1">Announcements</h3>

          <button onClick={this.newItem} className="btn btn-info" title="Add New" style={{ fontSize: '2rem', padding: 5, height: 50, width: 50 }}><FontAwesomeIcon icon={faPlus} transform="up-4" /></button>

          {

            this.state.announces.map((element, idx) => {
              let n = this.state.announces
              return (
                <div className="shadow p-3 my-5" key={`itemrow-${idx}`}>
                  <form onSubmit={(e) => this.saveItem(e, idx)} onChange={this.handleChange}>
                    <ReactQuill modules={this.quillModules} value={n[idx].announcement} placeholder="Announcement..." title="Announcement..." data-id={idx} data-name="announcement" className="my-2" onChange={(value) => this.handleQuillChange(value, idx)} />
                    <input value={n[idx].btn_label} ref={`announce_${idx}_btn_ttl`} data-id={idx} data-name="btn_label" className="form-control my-2" placeholder="Button Title..." title="Button Title..." onChange={this.handleChange} />
                    <input value={n[idx].btn_url} type="url" ref={`announce_${idx}_btn_lnk`} data-id={idx} data-name="btn_url" className="form-control my-2" placeholder="Button Link...." title="Button Link...." onChange={this.handleChange} />
                    <button type="submit" className="btn btn-success roundBtn" title="Save"><FontAwesomeIcon icon={faCheck} /></button>
                    <button onClick={(e) => this.deleteItem(e, n[idx].id)} className="btn btn-danger roundBtn" title="Delete"><FontAwesomeIcon icon={faTimes} /></button>
                  </form>
                </div>
              )
            })

          }

        </div>
      </div>
    )
  }




}
