import React from 'react';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function About(props){
    return(
        <section id="About" className="page">
			<div className="centerize white-bg">
				<div className="icon">
					<i><FontAwesomeIcon icon = {faInfoCircle} transform="up-4"/></i>
				</div>
				<h2 className="noor-h1">About</h2>
				<span dangerouslySetInnerHTML={{ __html: props.about }} />
			</div>
		</section>
    );
}

export default About;