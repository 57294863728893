import React from "react";
import Footer from './footer';
import Nav from '../nav/nav';
import Home from './home';
import Announcements from './announcements';
import TimeTable from './timetable';
import Madrassa from './madrassa';
import About from './about';
import Contact from './contact';
import ServicesOffered from './services';
import http from "../assets/http";
import helper from "../assets/helper";
import Lectures from "./Lectures";
import Donate from "./donate";
export default class HomePage extends React.Component {




  constructor() {
    super()

    this.state = {
      messages: [],
      currentTime: [],
      recordings: [],
      pages: [],
      startTime: {},
      services: '',
      about: '',
      contact: [],
    }

    console.log("fetching...")
    http.get('announcements.php')
      .then(Response => Response.json())
      .then(res => {
        let info = helper.ArraytoObj(res.info);

        this.setState({
          messages: res.announcements,
          currentTime: res.currentTime,
          recordings: res.recordings,
          pages: res.pages,
          startTime: res.startTime,
          services: info.services,
          about: info.about,
          contact: info,
        })



      })
      .catch(error => {
        console.log(error);
      })


     document.querySelector('body').classList.add('snap');
    // window.addEventListener("resize", function () {
    //   document.querySelector('body').classList.toggle('snapglitch');
    // });
  }




  render() {
    return (
      <div className="App">

        <div className="wrapper">
          <Home />
          <Donate />
          <Announcements msg={this.state.messages} />
          <TimeTable times={this.state.currentTime} startTime={this.state.startTime} />
          <Lectures rec = {this.state.recordings} pages={this.state.pages   }/>
          <Madrassa />
          <ServicesOffered services={this.state.services} />
          <About about={this.state.about} />
          <Contact contact={this.state.contact} />
        </div>
        <Footer data={this.state.contact} />
        <Nav />
      </div>
    );
  }




}
