import React from "react";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Lecture(props){
    return(
        <tr>
            <td><a onClick={()=>props.changeTrack(props.url)}><FontAwesomeIcon icon={faPlayCircle}/></a></td>
            <td>{props.category } </td>
            <td >{props.topic } </td>
            <td >{props.speaker } </td>
            <td className="d-none d-md-block">{props.lecture_date } </td>
        </tr>
    );
}

export default Lecture;