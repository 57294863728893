import React from 'react';
import img1 from '../images/masjidnoor.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer(props) {

  if (!props.data) return '';

  return (
    <div className="footer page bg-dark pb-0" id="footer">
      <div className="centerize">
        <div className="lead p-5">
          <img className="logo-image" src={img1} alt="" />
          <div>
            Masjid Al-Noor
            </div>
          <p>
          {
              props.data.fb ?
                <a className="footer" href={props.data.fb} rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} className="btn btn-dark roundBtn m-3" />
                </a>
              : ''
            }
            {
              props.data.insta ?
                <a className="footer" href={props.data.insta} rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} className="btn btn-dark roundBtn m-3" />
                </a>
              : ''
            }
            {
              props.data.twitter ?
                <a className="footer" href={props.data.twitter} rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} className="btn btn-dark roundBtn m-3" />
                </a>
              : ''
            }
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer