import React from "react";
import auth from "./auth";
import { withRouter } from 'react-router-dom'

class AdminLogoutPage extends React.Component {




  render() {
    auth.logout(() => {
      this.props.history.push("/");
    });
    return (
      <div className="page">
        <div className="centerize white-bg">
          <h1 className="noor-h1">Logging out...</h1>
        </div>
      </div>
    );
  }
}




export default withRouter(AdminLogoutPage)