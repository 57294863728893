import React, { useEffect } from "react";
import { faDonate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Donate() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
    script.async = true;
    script.onload = () => {
      window.PayPal.Donation.Button({
        env: 'production',
        hosted_button_id: 'MJP5ACF6ZFYXC',
        image: {
          src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
          alt: 'Donate with PayPal button',
          title: 'PayPal - The safer, easier way to pay online!',
        }
      }).render('#donate-button');
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }

  }, []);

  return (
    <section id='donate' className='page'>
      <dev className="centerize white-bg">
        <div className="icon">
          <i><FontAwesomeIcon icon={faDonate} transform="up-4" /></i>
        </div>
        <h2 className="noor-h1">Donations</h2>
        <p> Three way to donate:</p>
          <div className="row">
          <div className="col-lg-4">
            <div className="donate-box shadow">
            By check payable to Momin&nbsp;Services&nbsp;of&nbsp;AZ.
            Mailling Address: 55&nbsp;N.&nbsp;Matlock Mesa,&nbsp;AZ&nbsp;85203
          </div>
          </div>

          <div id="donate-button-container" className="col-lg-4">
          <div className="donate-box shadow">
            One time or recurring donations using PayPal <br/>
            <div id="donate-button"></div>
            </div>
          </div>

          <div className="col-lg-4">
          <div className="donate-box shadow">
            Send by Zelle&reg; to mominservicesofaz@gmail.com. Please mention reason for donation (Masjid, zakat, donation etc) in memo.
          </div>
          </div>
        </div>
      </dev>
    </section>
  );

}

export default Donate;