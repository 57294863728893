import React from 'react';
import { faSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function ServicesOffered(props){
    return(
        <section id="Services" className="page">
			<div className="centerize white-bg">
				<div className="icon">
					<i><FontAwesomeIcon icon={faSign} transform="up-4"/></i>
				</div>
				<h2 className="noor-h1">Services</h2>
				<span dangerouslySetInnerHTML={{ __html: props.services }} />
			</div>
		</section>
    );
}

export default ServicesOffered;