import React from 'react';
import './nav.css'

import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faDonate, faHome } from "@fortawesome/free-solid-svg-icons";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faMosque } from "@fortawesome/free-solid-svg-icons";
import { faSign } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Nav(){
    return(
        <nav id="nav">
			<a href="#Home" className="d-block nav-item"><i><FontAwesomeIcon icon={faHome}/></i><span className="nav-text">Home</span></a>
			<a href="#donate" className="d-block nav-item"><i><FontAwesomeIcon icon={faDonate}/></i><span className="nav-text">Donate</span></a>
			<a href="#Announcements" className="d-block nav-item"><i><FontAwesomeIcon icon={faBullhorn}/></i><span className="nav-text">Announcements</span></a>
			<a href="#PrayerTimetable" className="d-block nav-item"><i><FontAwesomeIcon icon={faClock}/></i><span className="nav-text">Prayer Timetable</span></a>
			<a href="#audio" className="d-block nav-item"><i><FontAwesomeIcon icon={faPlayCircle}/></i><span className="nav-text">Lectures</span></a>
			<a href="#Madrassa" className="d-block nav-item"><i><FontAwesomeIcon icon={faMosque}/></i><span className="nav-text">Madrassa</span></a>
			<a href="#Services" className="d-block nav-item"><i><FontAwesomeIcon icon={faSign}/></i><span className="nav-text">Services</span></a>
			<a href="#About" className="d-block nav-item"><i><FontAwesomeIcon icon={faInfoCircle}/></i><span className="nav-text">About</span></a>
			<a href="#Contact" className="d-block nav-item"><i><FontAwesomeIcon icon={faMapMarkerAlt}/></i><span className="nav-text">Contact</span></a>
	    </nav>
    );
}

export default Nav;