import React from "react";
import { api_url } from "../index";
import auth from "../login/auth";
import { withRouter } from 'react-router-dom';

// static LOGIN_PAGE = () => {return '/admin/login';}

class http extends React.Component {




  static post = (url, body = null) => {

    const token = localStorage.getItem('token');

    if (!body.has('token'))
      body.set('token', token);

    return fetch(api_url + url, {
      method: 'POST',
      body: body,
    })
  }




  static get = (url, params = "") => {

    // const token = localStorage.getItem('token');
    let endpoint = new URL(api_url + url);

    return fetch(endpoint, {
      method: 'GET',
    })
  }




  static processResponse(response) {
    if (response.status === 401) auth.logout(() => {
      localStorage.setItem('isLoggedOut', 1);
    });

    const ok = response.ok;
    const data = response.json();
    return Promise.all([ok, data]).then(res => ({
      ok: res[0],
      res: res[1]
    }));
  }




}

export default withRouter(http)