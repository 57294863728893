import React from "react";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import http from "../assets/http";

export default class AboutAdmin extends React.Component {




  constructor() {
    super()

    this.state = {
      about: '',
    }




    // Modules for QuillJs WYSIWYG editor
    this.quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image'],
        ['clean'],
      ],
    }
  }




  componentDidMount() {
    // Get about
    this.setState({
      about: this.props.about,
    })
  }




  handleQuillChange = (value) => {
    // Binding for QuillJs
    this.setState({ about: value })
  }




  // Save an item
  saveItem(e) {
    e.preventDefault()
    e.persist()

    // Verify empty HTML
    if (!this.state.about ||
      this.state.about === "<p><br></p>" ||
      this.state.about.replace(/(<p>|<\/p>|<br>|&nbsp;|\s+)*/g, '') === "") {
      alert("Cannot save an empty details!!!")
      return;
    }

    // Add loading effect
    e.target.classList.add('loading');

    // Create form data
    let form = new FormData()
    form.set('method', 'update_info')
    form.set('property', 'about')
    form.set('data', this.state.about)

    // Send form data
    http.post('admin.php', form)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok && res.hasOwnProperty('data') && res.data[0]) {

          let service_data = res.data.filter(function (el) {
            return el.id === 'about';
          })

          this.setState({ about: service_data[0].data })
          e.target.classList.remove('loading');

        }
      })
  }




  render() {
    return (
      <div id="About" className="page">
        <div className="white-bg centerize" style={{ minWidth: 500 }}>

          <h3 className="noor-h1">About</h3>
          <form id="AboutForm" onSubmit={(value) => this.saveItem(value)} >
            <ReactQuill modules={this.quillModules} value={this.state.about} placeholder="About..." className="my-2" onChange={(value) => this.handleQuillChange(value)} />
            <button type="submit" className="btn btn-success roundBtn" title="Save"><FontAwesomeIcon icon={faCheck} /></button>
          </form>
        </div>
      </div>
    )
  }




}