import React from "react"

function TimerEntry(props){
    var jamah = props.h+":"+props.m +" " +props.ap;
    var stTime;
    switch(props.id) {
        case 1:
          stTime = props.startTime.a + " AM";
          break;
        case 2:
          stTime = props.startTime.b + " PM";
          break;
        case 3:
          stTime = props.startTime.c + " PM";
          break;
        case 4:
          stTime = props.startTime.d + " PM";
          break;
        case 5:
          stTime = props.startTime.e + " PM";
          break;
        case 6:
          stTime = props.startTime.f + " PM";
          break;
        default:
          console.log("error in TimeEntry switch stmet", props.id)
      }
    if(props.id === 4){
        jamah = "Sunset";
    }
    return(
        <tr>
            <td>{props.prayer}</td>
            <td><span>{stTime}</span></td>
            <td><span>{jamah}</span></td>
        </tr>
    );
}

export default TimerEntry