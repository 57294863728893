import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles.css';
import HomePage from './home/HomePage';
import AdminPage from './admin/AdminPage';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import AdminLoginPage from './login/LoginPage';
import AdminLogoutPage from './login/LogoutPage';
import { ProtectedRoute } from './login/protected.route';
import RedirectToHome from './assets/RedirectToHome';

//export const api_url = 'https://masjidnoormesa.com/api/'
export const api_url = 'https://staging2.masjidnoormesa.com/api/'
// export const api_url = 'http://localhost/api/'

const routing = (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/admin/login" component={AdminLoginPage} />
    <Route exact path="/admin/logout" component={AdminLogoutPage} />
    <ProtectedRoute exact path="/admin" component={AdminPage} />
    <Route component={RedirectToHome} />
  </Switch>
)

ReactDOM.render(<BrowserRouter>{routing}</BrowserRouter>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
