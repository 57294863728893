import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { withRouter } from 'react-router-dom';
import http from '../assets/http';
import auth from "./auth";

class AdminLoginPage extends React.Component {




  constructor() {
    super()
    this.login = this.login.bind(this);
    this.state = {
      pageloading: true,
      loading: false,
      login_error: false,

    };

  }




  componentDidMount() {
    if (localStorage.hasOwnProperty('token') && localStorage.token !== null && localStorage.loken !== "") {
      this.setState({pageloading: true})
      this.isAlreadyLoggedIn();
    }
    else {
      this.setState({pageloading: false})
    }
  }




  isAlreadyLoggedIn(){
      auth.validateToken((loggedIn) => {
        if (loggedIn) {
        auth.login(() => {
          this.setState({ pageloading: false, login_error: false,  })
          auth.isAuthenticated()
          this.props.history.push("/admin");
        });
        }
        else{
          auth.logout(()=>{
            this.setState({ pageloading: false, login_error: false,  })
          });
        }
      });
  }




  login(event) {
    event.preventDefault();

    this.setState({ loading: true })

    const formData = new FormData();
    formData.set("method", 'login');
    formData.set("username", this.refs.username.value);
    formData.set("password", this.refs.password.value);


    auth.clearToken();


    http.post('admin.php', formData)
      .then(http.processResponse)
      .then(response => {

        const { ok, res } = response;

        // Successful login
        if (ok && res.hasOwnProperty('data') && res.data.hasOwnProperty('token')) {
          localStorage.setItem('token', res.data.token)

          auth.login(() => {
            this.setState({ loading: false, login_error: false,  })
            this.props.history.push("/admin");
          });

        }
        // Authentication failed
        else if (ok && res.hasOwnProperty('data') && res.data.hasOwnProperty('er_msg')) {
          alert(res.data.er_msg)
          auth.logout(() => {
            this.setState({ loading: false, login_error: true,  })
          });
        }
        // Exception thrown
        else {
          alert('error' + JSON.stringify(res))
          auth.logout(() => {
            this.setState({ loading: false, login_error: true,  })
          });
        }
      })
  }


  

  render() {
    if (auth.isAuthenticated() === true) {
      this.props.history.push("/admin");
    }

    if (this.state.pageloading === true) {
      return (
        <div className="page">
          <div className="centerize white-bg">
            <h1 className="noor-h1">
              Loading
              <FontAwesomeIcon icon={faSpinner} className={this.state.pageloading ? 'ml-3 text-danger' : 'd-none'} spin />
              </h1>
          </div>
        </div>
      );
    }


    return (

      <section className="page">
        <div className="centerize white-bg">
          <h1 className="noor-h1">Hello Admin</h1>
          <form onSubmit={this.login} className="centerize" style={{ maxWidth: 500 + 'px' }}>

            <input required type="text" name="user" ref="username" className={`form-control my-3 ${this.state.login_error !== false ? 'is-invalid' : ''}`} autoFocus placeholder="User name" />
            <div className="my-3">
              <input required type="password" name="password" ref="password" className={`form-control ${this.state.login_error !== false ? 'is-invalid' : ''}`} placeholder="Password" />
              <div className={this.state.login_error !== false ? "invalid-feedback" : 'd-none'}>{this.state.login_error}</div>
            </div>
            <input className={this.state.loading ? 'btn btn-secondary' : 'btn btn-info'} type="submit" value="Login" disabled={this.state.loading} />
            <FontAwesomeIcon icon={faSpinner} className={this.state.loading ? 'ml-3 text-danger' : 'd-none'} spin />
          </form>
        </div>
      </section>
    );
  }
}


export default withRouter(AdminLoginPage)