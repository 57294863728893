import React from "react";
import http from "../assets/http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default class CurrentPrayerAdmin extends React.Component {

  constructor() {
    super()
    var date = new Date();
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
    this.state = {
      namaz: [
        { timeid: 1, prayername: '', time: '' },
      ],
      dateC:  dateString
    }
  }

  handleChange = (e) => {
    if (e.target.getAttribute('data-id')) {
      let namaz = [...this.state.namaz]
      namaz[e.target.dataset.id][e.target.dataset.name] = e.target.value
      this.setState({ namaz: namaz })
    }
  }

  handleDateChange = (e) => {
      console.log(e.target.value);
      this.setState({ dateC:  e.target.value });
  }

  componentDidMount() {
    this.setState({
      namaz: this.props.times
    })
  }




  handleSubmit = (e) => {
    e.preventDefault();
    e.persist()

    e.target.classList.add('loading');

    let data = new FormData();
    data.set('times', JSON.stringify(this.state.namaz));
    data.set('dateC', this.state.dateC);
    data.set('method', 'update_prayer_times');

    http.post('admin.php', data)
      .then(http.processResponse)
      .then(response => {
        const { ok, res } = response;
        if (ok && res.hasOwnProperty('data')) {
          e.target.classList.remove('loading');
        }
      });
  }

  handleCSV = (e)=>{
    let data = new FormData();
    data.set('method', 'get_prayer_times_csv');
    http.post('admin.php', data)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `ChangeLog.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });

  }




  render() {
    return (
      <div id="Currentnamaz" className="page">
        <div className="white-bg centerize">
          <h3 className="noor-h1">Prayer Times</h3>
          <form onSubmit={this.handleSubmit}>
            <input className="form-control  text-center" type="date" value={this.state.dateC} data-name="date" onChange={this.handleDateChange}/>

            {

              this.state.namaz.map((element, idx) => {
                let n = this.state.namaz
                let i = idx
                return (
                  <div className="form-row" key={`itemrow-${i}`}>
                    <div className="col-6 my-2">
                      <input className="form-control" type="hidden" value={n[i].timeid} data-id={i} data-name="id" onChange={this.handleChange} />
                      <input className="form-control  text-center" type="text" value={n[i].prayername} data-id={i} data-name="prayername" onChange={this.handleChange} />
                    </div>
                    <div className="col-6 my-2">
                      <input className="form-control  text-center" type="time" value={n[i].time} data-id={i} data-name="time" onChange={this.handleChange} />
                    </div>
                  </div>
                )
              })

            }

            <button type="submit" className="btn btn-success roundBtn" title="Save"><FontAwesomeIcon icon={faCheck} /></button>
          </form>
          <br/>
          <a className="m-2" onClick={this.handleCSV} download>Click to download</a>
        </div>
      </div>
    )
  }




}
