import React from 'react';
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeEntry from './TimeEntry'

function TimeTable(props){
  const timeList = props.times.map(t => (
    <TimeEntry
      key={t.timeid}
      id={t.timeid}
      prayer={t.prayername}
      h={t.timehour}
      m={t.timeminute}
      ap={t.timeampm}
      startTime={props.startTime}
    />
  ));
    return(
        <section id="PrayerTimetable" className="page">
			<div className="centerize white-bg">
				<div className="icon">
					<i><FontAwesomeIcon icon={faClock}  transform="up-4"/></i>
				</div>
				<h2 className="noor-h1">Prayer Timetable</h2>
				<span ><script type="text/javascript">document.write(new Date().toDateString());</script></span>

				<table id="prayertable" className="table table-striped" align="center" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>Salah</th>
              <th>Begins</th>
              <th>Jama'ah</th>
            </tr>
          </thead>
          <tbody>
					  {timeList}
          </tbody>
        </table>

			</div>
		</section>


    );
}

export default TimeTable;