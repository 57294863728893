import React, {useState, useEffect} from 'react';
import img1 from '../images/masjidnoor.png';


function Home (props){
	const [theposition, setTheposition] = useState(false)

	const listenToScroll = () => {
		const winScroll =
		  document.body.scrollTop || document.documentElement.scrollTop

		const height =
		  document.documentElement.scrollHeight -
		  document.documentElement.clientHeight

		const scrolled = winScroll / height

		setTheposition(scrolled >=0.033? true:false)
	  }

	  useEffect(()=>{
		window.addEventListener('scroll', listenToScroll)
		return ()=>{window.removeEventListener('scroll', listenToScroll)}
	  },[])


	var className = theposition ? 'logo d-flex logo-state2' : 'logo d-flex logo-state1';
	var className2 = theposition ? 'donate2' : 'donate';
	return(
		<section id="Home" className="page masjidbg">
			<div id="logo"  className={className}>
				<div className="logo-wrapper centerize">
					<img className="logo-image" src={img1} alt=""/>
					<div className="logo-text">
						<h1 className="noor-h1 logo-text-en">Masjid Al-Noor</h1>
						<h1 className="noor-h1 logo-text-ar">
							مسجد النور
						</h1>
					</div>
				</div>
				{/* <div className={className2}>
					<a href="https://www.paypal.com/donate?hosted_button_id=MJP5ACF6ZFYXC" >
						<img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" alt="Donate with PayPal"/>
					</a>
				</div> */}
			</div>
		</section>

	);


}

export default Home;