import React from 'react';
import Announcement from './Announcement'
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Announcements(props) {
  const announcementList = props.msg.map(msg =>
    <Announcement
      key={msg.id}
      message={msg.announcement}
      btn_label={msg.btn_label}
      btn_url={msg.btn_url}
    />)
  return (
    <section id="Announcements" className="page">
      <div className="centerize white-bg">
        <div className="icon">
          <i><FontAwesomeIcon icon={faBullhorn} transform="up-4" /></i>
        </div>
        <h2 className="noor-h1">Announcements</h2>
        {announcementList}
      </div>
    </section>

  );

}

export default Announcements;